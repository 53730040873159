import React, { RefObject } from "react";
import dynamic from "next/dynamic";

import useResizingRects from "hooks/useResizingRects";

const StrainLineageOverlayTwoParents = dynamic(
  () => import("./StrainLineageOverlayTwoParents"),
);
const StrainLineageOverlayTwoChildren = dynamic(
  () => import("./StrainLineageOverlayTwoChildren"),
);
const StrainLineageOverlaySingleParent = dynamic(
  () => import("./StrainLineageOverlaySingleParent"),
);
const StrainLineageOverlaySingleChild = dynamic(
  () => import("./StrainLineageOverlaySingleChild"),
);

type Props = {
  refs: Record<string, RefObject<HTMLDivElement>>;
  numberOfParents: number;
  numberOfChildren: number;
};

const StrainLineageOverlay = ({
  refs,
  numberOfParents,
  numberOfChildren,
}: Props) => {
  const {
    lineageRect,
    strainRect,
    strainTextRect,
    parent1Rect,
    parent2Rect,
    child1Rect,
    child2Rect,
  } = useResizingRects(refs);

  return lineageRect ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="absolute text-secondary stroke-current"
      style={{ zIndex: -1 }}
      viewBox={`${lineageRect.x} ${lineageRect.y} ${lineageRect.width} ${lineageRect.height}`}
      height={lineageRect.height}
    >
      {numberOfParents === 2 && parent1Rect && parent2Rect && (
        <StrainLineageOverlayTwoParents
          parent1Rect={parent1Rect}
          parent2Rect={parent2Rect}
          strainRect={strainTextRect}
        />
      )}

      {numberOfParents === 1 && (parent1Rect || parent2Rect) && (
        <StrainLineageOverlaySingleParent
          parentRect={parent1Rect || parent2Rect}
          strainRect={strainRect}
        />
      )}

      {numberOfChildren === 2 && child1Rect && child2Rect && (
        <StrainLineageOverlayTwoChildren
          child1Rect={child1Rect}
          child2Rect={child2Rect}
          strainRect={strainTextRect}
        />
      )}

      {numberOfChildren === 1 && (child1Rect || child2Rect) && (
        <StrainLineageOverlaySingleChild
          childRect={child1Rect || child2Rect}
          strainRect={strainRect}
        />
      )}
    </svg>
  ) : (
    <></>
  );
};

export default StrainLineageOverlay;
