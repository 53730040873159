"use client";

import React, { ReactNode } from "react";

import Background from "./internal/Background";
import {
  MissingModalContextError,
  ModalConsumer,
  ModalProvider,
  useModalContext,
} from "./internal/ModalContext";
import { ModalPortal } from "./internal/ModalPortal";
import { ModalRenderProvider } from "./internal/ModalRenderContext";

export class MultipleModalFrameError extends Error {
  constructor() {
    super(`
ModalFrame should only be rendered once in the application tree.
Remove all ModalFrames except for the one that is mounted at the
highest point in the React render tree.
    `);
    Object.setPrototypeOf(this, MultipleModalFrameError.prototype);
  }
}

export function ModalFrame({
  children,
  __testing,
}: {
  children: ReactNode;
  __testing?: boolean;
}) {
  /**
   * ModalFrame should only be rendered once in the appication, so this check
   * ensures that there is not already a previously mounted ModalContext.
   */
  try {
    if (useModalContext()) {
      throw new MultipleModalFrameError();
    }
  } catch (e) {
    if (e instanceof MissingModalContextError) {
      /**
       * Do nothing. Calling useModalContext is expected to throw, as there
       * should be no modal context mounted at this point. This will ensure that
       * ModalFrame is only mounted once in the application.
       */
    } else {
      throw e;
    }
  }

  return (
    <ModalProvider __testing={__testing}>
      <ModalRenderProvider>
        <ModalConsumer>
          {(props) => {
            const hasCount = !!(props?.count && props.count > 0);
            return (
              <>
                <div aria-hidden={hasCount} id="modal-frame">
                  {children}
                </div>
                <Background inProp={hasCount} />
              </>
            );
          }}
        </ModalConsumer>
        <ModalPortal />
      </ModalRenderProvider>
    </ModalProvider>
  );
}
