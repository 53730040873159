import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "redux/reducers/rootReducer";

const getComplianceRulesState = (state: RootState) =>
  state.complianceRuleset?.complianceRuleset?.rules;

export const getIsThirdPartyOrderingProhibited = createSelector(
  [getComplianceRulesState],
  (rules) => !!rules?.thirdPartyOrderingProhibited,
);

export const getIsMenuProhibited = createSelector(
  [getComplianceRulesState],
  (rules) => !!rules?.menuProhibited,
);

export const getIsOrderingAllowed = createSelector(
  [getComplianceRulesState],
  (rules) =>
    !!(
      rules?.pickupAllowed ||
      rules?.medicalDeliveryAllowed ||
      rules?.recreationalDeliveryAllowed
    ),
);

export const getEducationalMaterials = createSelector(
  [getComplianceRulesState],
  (rules) => rules?.educationalMaterials,
);

export const getAreCategoryCoinIconsHidden = createSelector(
  [getComplianceRulesState],
  (rules) => rules?.storefrontHideCategoryCoinIcons,
);

export const getRecreationalAlternateTerm = createSelector(
  [getComplianceRulesState],
  (rules) => rules?.recreationalTerminologyAlternative as string | undefined,
);

export const getIsDelta8Allowed = createSelector(
  [getComplianceRulesState],
  (rules) => rules?.delta8Allowed,
);

export const getIsDelta9Allowed = createSelector(
  [getComplianceRulesState],
  (rules) => rules?.delta9Allowed,
);
