"use client";

import { useRef } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import { getShowBanner } from "redux/selectors/header";

import { AdvertisingLink } from "./AdvertisingLink";
import { Banner } from "./Banner";
import Cart from "./Cart";
import { HamburgerButton } from "./HamburgerButton";
import { HeaderLinks } from "./HeaderLinks";
import { LocationIndicator } from "./LocationIndicator";
import { LocationPickerModal } from "./LocationPickerModal";
import { Logo } from "./Logo";
import { NotificationIndicator } from "./NotificationIndicator";
import { SearchBar } from "./SearchBar";
import { SearchBarSlim } from "./SearchBarSlim";
import SidebarNavigation from "./SidebarNavigation/SidebarNavigation";

export const Header: React.FC<{
  disableFixedPosition?: boolean;
  isAppBannerCookieDismissed?: boolean;
  minimal?: boolean;
}> = ({
  disableFixedPosition = false,
  isAppBannerCookieDismissed = false,
  minimal = false,
}) => {
  const hamburgerRef = useRef<HTMLButtonElement>(null);
  const locationIndicator = useRef<HTMLButtonElement>(null);
  const showBanner = useSelector(getShowBanner);

  return (
    <div
      className={classNames(
        "transition-[padding-top] motion-reduce:transition-none",
        {
          "pt-0 lg:pt-[--header-height]": disableFixedPosition,
          "pt-[calc(var(--app-banner-height)+var(--header-height))]":
            !disableFixedPosition,
        },
      )}
    >
      <div
        /**
         * The transitions defined in the CSS below are not just for fun! The metrics
         * that Google collects on Cumulative Layout Shift counts the change in the
         * header when scrolling up and down as Layout Shift, even though it is
         * technically triggered by user interaction; the scroll event is not included
         * in the list of user events that can trigger document reflow.
         * See further documentation here: https://wicg.github.io/layout-instability/#sec-input-exclusion
         */
        className={classNames("z-header w-full bg-white text-green top-0", {
          collapsed: minimal,
          "fixed transition-transform motion-reduce:transition-none transform lg:transform-none":
            !disableFixedPosition,
          "relative lg:fixed": disableFixedPosition,
          "translate-y-[-82px]":
            !disableFixedPosition && (!showBanner || minimal),
        })}
        data-testid="header"
        id="header-container"
      >
        <Banner
          disableFixedPosition={disableFixedPosition}
          isAppBannerCookieDismissed={isAppBannerCookieDismissed}
          minimal={minimal}
        />

        <header className="shadow-low relative">
          <div className="container pb-md lg:py-lg pt-md">
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex">
                <HamburgerButton ref={hamburgerRef}>
                  <NotificationIndicator data-testid="notification-count" />
                </HamburgerButton>
                <Logo />
                <AdvertisingLink />
              </div>
              <div className="hidden lg:block flex-1">
                {!minimal && <SearchBar />}
              </div>
              <div className="flex items-center justify-center">
                {!minimal && <SearchBarSlim />}
                {!minimal && <LocationIndicator ref={locationIndicator} />}
                <Cart />
              </div>
            </div>
            {!minimal && <HeaderLinks />}
          </div>
        </header>
      </div>

      <LocationPickerModal returnFocusRef={locationIndicator} />
      <SidebarNavigation returnFocusRef={hamburgerRef} />

      <style jsx global>{`
        :root {
          --header-height: ${minimal ? 48 : 52}px;
          --app-banner-height: ${showBanner ? 82 : 0}px;
        }

        @media (min-width: 1025px) {
          :root {
            --header-height: ${minimal ? 56 : 106}px;
            --app-banner-height: 0px;
          }
        }
      `}</style>
    </div>
  );
};
