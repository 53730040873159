import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { Strain } from "custom-types/Strain";

import Image from "components/Image";

const StrainImage = ({
  strain,
  showSimilarStrainIcon = true,
}: {
  strain: Strain;
  showSimilarStrainIcon: boolean;
}) => {
  const { name, nugImage, slug, subtitle, stockNugImage } = strain;

  return (
    <Link
      href={`/strains/${slug}`}
      className="no-underline"
      onClick={() => {
        trackEvent("Strain Comparison", "Click", "Similar Strain", {
          strainId: strain.id,
        });
      }}
    >
      <div className="relative bg-white mb-sm">
        <Image
          src={nugImage || stockNugImage || ""}
          alt={`Nug image for ${strain.name}`}
          sizes={[180]}
          ratio="square"
        />
        <>
          {showSimilarStrainIcon && (
            <div className="absolute mr-sm z-10">
              <span
                className="border border-default absolute"
                style={{
                  borderRadius: "50%",
                  bottom: -5,
                  height: 10,
                  width: 10,
                }}
              />
              <span
                className="border border-default absolute"
                style={{
                  borderRadius: "50%",
                  bottom: -5,
                  height: 10,
                  left: 5,
                  width: 10,
                }}
              />
            </div>
          )}
        </>
      </div>
      <p className="text-sm font-bold mt-none mb-none">{name}</p>
      <p className="text-xs mt-none mb-none">{subtitle}</p>
    </Link>
  );
};

export default StrainImage;
