"use client";

import React, { useEffect, useRef, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import classNames from "classnames";
import { addHours, addYears, isAfter } from "date-fns";
import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import FocusLock from "react-focus-lock";

import publicConfig from "config/public";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { availableStorage } from "utils/storage";
import { getUserAgent, userAgentIsGoogleBot } from "utils/userAgent";

import Checkbox from "components/botanic/Checkbox";
import LegalLogo from "components/Icons/legal-logo.svg";

const CanadianAgeGateForm = dynamic(() => import("./CanadianAgeGateForm"), {
  loading: () => <div className="min-h-[80px]" />,
});
const UsAgeGateForm = dynamic(() => import("./UsAgeGateForm"), {
  loading: () => <div className="min-h-[140px] md:min-h-[92px]" />,
});

const { leaflyDomain, leaflyCaDomain } = publicConfig;

const REDIRECTS_BY_COUNTRY_CODE: Record<string, string> = {
  CA: leaflyCaDomain,
  US: leaflyDomain,
};

enum StorageKeys {
  country = "acceptedTermsOfUseCountry",
  expiryDate = "acceptedTermsExpiryDate",
  firstVisitDate = "firstVisitDate",
  isAgeVerified = "isAgeVerified",
  version = "acceptedTermsOfUseVersion",
}

const TOU_VERSIONS: Record<string, number> = {
  CA: 3,
  US: 3,
};

export type AgeGateFormProps = {
  confirm: () => void;
  decline?: () => void;
  setRegionSelected?: (regionSelected: boolean) => void;
};

const parseStorage = (storage: Storage) => ({
  country: storage.getItem(StorageKeys.country),
  expiryDate: storage.getItem(StorageKeys.expiryDate),
  version: storage.getItem(StorageKeys.version),
});

const checkTermsAcceptance = (
  countryCode: string,
  storage: ReturnType<typeof parseStorage>,
) => {
  if (!storage.country || !storage.expiryDate || !storage.version) {
    return false;
  }

  const touVersion = TOU_VERSIONS[countryCode.toUpperCase()];
  const versionsMatch = touVersion === parseInt(storage.version, 10);
  const countriesMatch =
    countryCode.toLowerCase() === storage.country.toLowerCase();
  const expired = isAfter(new Date(), new Date(storage.expiryDate));

  return versionsMatch && !expired && countriesMatch;
};

const AgeGate: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const domainCountryCode = useDomainCountryCode();
  const query = useSearchParams();
  const pathname = usePathname() || "";

  const [showModal, setShowModal] = useState(false);
  const [rememberUser, setRememberUser] = useState(true);
  const [redirectingDomain, setRedirectingDomain] = useState<string>();
  const [ageIsInvalid, setAgeIsInvalid] = useState(false);
  const [regionSelected, setRegionSelected] = useState(false);

  const decline = () => setAgeIsInvalid(true);

  const confirm = () => {
    const storage = availableStorage();

    if (storage) {
      const expiryAmount = rememberUser
        ? addYears(new Date(), 1)
        : addHours(new Date(), 1);

      const storageItems = {
        [StorageKeys.country]: domainCountryCode.toLowerCase(),
        [StorageKeys.version]:
          TOU_VERSIONS[domainCountryCode.toUpperCase()] + "",
        [StorageKeys.expiryDate]: expiryAmount.toUTCString(),
        [StorageKeys.isAgeVerified]: "true",
        [StorageKeys.firstVisitDate]: new Date().toISOString(),
      };

      Object.keys(storageItems).forEach((key) =>
        storage.setItem(key, storageItems[key as StorageKeys]),
      );
    }

    setShowModal(false);
  };

  const shouldRedirect = (selectedCountryCode: string) => {
    if (selectedCountryCode !== domainCountryCode) {
      const redirectUrl = REDIRECTS_BY_COUNTRY_CODE[selectedCountryCode];

      setRedirectingDomain(redirectUrl);
      window.location.assign(redirectUrl);
    }
  };

  useEffect(() => {
    const storage = availableStorage();
    const isInfoPage = new RegExp(
      "^/info/(privacy-policy|terms-of-use|commercial-terms-of-use|jobs|contact|about)/?$",
    ).test(pathname);

    const enableAgeGate =
      (domainCountryCode !== "US" ||
        new RegExp("^(/dispensary-info)/*").test(pathname)) &&
      !query?.has("disable_age_gate") &&
      !isInfoPage;

    const termsHaveBeenAccepted =
      storage && checkTermsAcceptance(domainCountryCode, parseStorage(storage));
    const isGoogleBot = userAgentIsGoogleBot(getUserAgent());

    if (enableAgeGate && !termsHaveBeenAccepted && !isGoogleBot) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [domainCountryCode, pathname]);

  useEffect(() => {
    const scrollLockTarget = containerRef.current;
    if (showModal) {
      scrollLockTarget && disableBodyScroll(scrollLockTarget);
    } else {
      scrollLockTarget && enableBodyScroll(scrollLockTarget);
    }
    return () => {
      scrollLockTarget && enableBodyScroll(scrollLockTarget);
    };
  }, [showModal]);

  return showModal ? (
    <FocusLock>
      <div
        className={classNames(
          "bg-[#ffecd3] z-[2147483647] fixed flex inset-0 items-start md:h-auto md:items-center overflow-auto",
        )}
        data-testid="age-gate-modal"
        ref={containerRef}
      >
        <div className="md:h-auto mx-auto max-w-full w-[calc(100%-16px)] lg:w[-476px] max-w-[500px]">
          <div className="md:h-auto mx-auto my-sm p-lg lg:p-xxl text-sm bg-leafly-white border border-light-grey shadow-md rounded max-w-full">
            <div className="mb-sm text-green">
              <LegalLogo width="144" height="64" data-testid="leafly-logo" />
            </div>
            <div
              className="font-extrabold mb-xl text-md"
              data-testid="age-gate-title-text"
            >
              Learn. Shop. Get cannabis.
            </div>

            <div className="pb-sm">
              <span className="text-xs mr-xs">Where are you from?</span>
              <select
                id="age-gate-country"
                data-testid="age-gate-modal-select"
                className="bg-light-grey rounded font-bold items-center text-sm p-md px-lg z-20"
                aria-label="Select your country"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  shouldRedirect(e.target.value)
                }
                defaultValue={domainCountryCode}
              >
                <option value="US">United States</option>
                <option value="CA">Canada</option>
              </select>
            </div>

            {domainCountryCode === "CA" ? (
              <CanadianAgeGateForm
                confirm={confirm}
                setRegionSelected={setRegionSelected}
              />
            ) : (
              <UsAgeGateForm confirm={confirm} decline={decline} />
            )}

            {(regionSelected || domainCountryCode !== "CA") && (
              <div className="mt-lg">
                <Checkbox
                  data-testid="age-gate-modal-checkbox"
                  id="age-gate-remember"
                  checked={rememberUser}
                  onChange={() => setRememberUser(!rememberUser)}
                >
                  <>
                    Remember me. <br />I confirm that this is not a shared
                    device.
                  </>
                </Checkbox>
              </div>
            )}
            {redirectingDomain && <p>Redirecting you to {redirectingDomain}</p>}
            {ageIsInvalid && (
              <p
                className="mt-xl text-error"
                data-testid="age-gate-modal-invalid"
              >
                Darn! You're not old enough to use Leafly.
              </p>
            )}
          </div>
          <div className="mx-auto md:h-auto px-xl py-xl text-sm">
            <p className="text-cs mt-auto">
              By accessing this site, you accept the{" "}
              <Link
                className="py-xl"
                data-testid="tou-link"
                href="/info/terms-of-use"
              >
                Terms of Use
              </Link>{" "}
              and{" "}
              <Link
                className="py-xl"
                data-testid="pp-link"
                href="/info/privacy-policy"
              >
                Privacy Policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </FocusLock>
  ) : (
    <></>
  );
};

export default AgeGate;
