// Do not remove following two lazysizes imports. If this is removed most images will not load.
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "../styles/index.css";

import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { Provider as ReduxProvider } from "react-redux";

import publicConfig from "config/public";
import { textFont } from "constants/fonts";
import { wrapper } from "redux/wrapper";

import DatadogBrowserLogs from "components/DatadogBrowserLogs";
import Layout from "components/Layout";

export type LeaflyAppProps = {
  disableFixedHeader?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
  isAppBannerCookieDismissed?: boolean;
  showMinimalHeader?: boolean;
  /**
   * Permits passing the Datadog configuration from the server to the client.
   * This will be defined on the first page load, but not on any subsequent
   * client-side navigations.
   */
  datadog: {
    env?: string;
    version?: string;
  };
};

const LeaflyApp = ({
  Component,
  pageProps,
  ...rest
}: AppProps<LeaflyAppProps>) => {
  const { store } = wrapper.useWrappedStore({ pageProps, ...rest });

  const {
    disableFixedHeader,
    hideFooter,
    hideHeader,
    isAppBannerCookieDismissed,
    showMinimalHeader,
    datadog,
    ...remainingPageProps
  } = pageProps;

  const { pathname } = useRouter();

  return (
    <ReduxProvider store={store}>
      {!showMinimalHeader && (
        /**
         * Note: changes to metadata defined in this <Head> component should
         * also be reflected in the `generateMetadata` function in `app/layout`!
         */
        <Head>
          {/* For iPad */}
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="https://public.leafly.com/favicon/apple-touch-icon.png"
          />
          {/* For iPhone */}
          <link
            rel="apple-touch-icon"
            sizes="167x167"
            href="https://public.leafly.com/favicon/favicon-167x167.png"
          />
          {/* For all browsers */}
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="https://public.leafly.com/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="https://public.leafly.com/favicon/favicon-16x16.png"
          />
          {/* For Google and Android */}
          <link
            rel="icon"
            type="image/png"
            sizes="48x48"
            href="https://public.leafly.com/favicon/favicon-48x48.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="192x192"
            href="https://public.leafly.com/favicon/favicon-192x192.png"
          />

          <link
            rel="mask-icon"
            href="https://public.leafly.com/favicon/safari-pinned-tab.svg"
            color="#034638"
          />
          <link
            rel="shortcut icon"
            href="https://public.leafly.com/favicon/favicon.ico"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="Leafly" />
          <meta name="application-name" content="Leafly" />
          <meta name="theme-color" content="#034638" />
          <meta property="og:site_name" content="Leafly" key="og:site_name" />
          <meta
            key="twitter:card"
            name="twitter:card"
            content={pathname === "/" ? "app" : "summary"}
          />
          <meta name="twitter:site" content="@Leafly" key="twitter:site" />
          <meta property="og:type" content="website" key="og:type" />
          <meta name="twitter:app:country" content="US" />
          <meta name="twitter:app:id:iphone" content="416456429" />
          <meta name="twitter:app:id:ipad" content="416456429" />
          <meta name="twitter:app:id:googleplay" content="leafly.android" />
          {publicConfig.robots.noindexAll && (
            <meta name="robots" content="noindex,noarchive" />
          )}
        </Head>
      )}

      <DatadogBrowserLogs {...datadog} />
      <Layout
        disableFixedHeader={disableFixedHeader}
        hideFooter={hideFooter}
        hideHeader={hideHeader}
        isAppBannerCookieDismissed={isAppBannerCookieDismissed}
        showMinimalHeader={showMinimalHeader}
      >
        <Component {...remainingPageProps} />
      </Layout>

      <style jsx global>{`
        html {
          font-family: ${textFont.style.fontFamily};
        }
      `}</style>
    </ReduxProvider>
  );
};

export default LeaflyApp;
