import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "redux/reducers/rootReducer";

const getHeaderState = (state: RootState) => state.header;

export const getNavigationOpen = createSelector(
  [getHeaderState],
  (state) => state.navigationOpen,
);

export const getHeaderCollapsed = createSelector(
  [getHeaderState],
  (state) => state.collapsed,
);

export const getNotificationCount = createSelector(
  [getHeaderState],
  (state) => state.notificationCount,
);

export const getSearchOpen = createSelector(
  [getHeaderState],
  (state) => state.search.open,
);

export const getSearchSuggestionsOrRecentHistory = createSelector(
  [getHeaderState],
  (state) =>
    state.search.suggestions?.length > 0
      ? state.search.suggestions
      : state.search.recentSearches,
);

export const getShowingRecentHistory = createSelector(
  [getHeaderState],
  (state) => !state.search.suggestions || state.search.suggestions.length === 0,
);

export const getReloadPageAfterLocationChange = createSelector(
  [getHeaderState],
  (state) => state.reloadPageAfterLocationChange,
);

export const getShowBanner = createSelector(
  [getHeaderState],
  (state) => state.showBanner,
);
