import React, { useRef } from "react";
import dynamic from "next/dynamic";

import { useStrainPageContext } from "context/StrainPageContext";

import TrackImpression from "components/TrackImpression";

import Section from "../Section";
import StrainLineageOverlay from "./StrainLineageOverlay";

const StrainLineageSingleParent = dynamic(
  () => import("./StrainLineageSingleParent"),
);
const StrainLineageTwoParents = dynamic(
  () => import("./StrainLineageTwoParents"),
);
const StrainLineageSingleChild = dynamic(
  () => import("./StrainLineageSingleChild"),
);
const StrainLineageTwoChildren = dynamic(
  () => import("./StrainLineageTwoChildren"),
);

import { extractTopTerp } from "../../../utils/strains/extractTopTerp";
import StrainLineageNuclei from "./StrainLineageNuclei";

const StrainLineage = () => {
  const { strain } = useStrainPageContext();
  const { children, name, parents, symbol, terps } = strain;

  const numberOfParents = parents ? parents.length : 0;
  const numberOfChildren = children ? children.length : 0;
  const parentalClassExtension =
    numberOfParents === 2
      ? "two-parents"
      : numberOfParents === 1
        ? "single-parent"
        : "no-parents";

  const lineageRef = useRef<HTMLDivElement>(null);
  const parent1Ref = useRef<HTMLDivElement>(null);
  const parent2Ref = useRef<HTMLDivElement>(null);
  const strainRef = useRef<HTMLDivElement>(null);
  const strainTextRef = useRef<HTMLDivElement>(null);
  const child1Ref = useRef<HTMLDivElement>(null);
  const child2Ref = useRef<HTMLDivElement>(null);
  const refs = {
    child1Ref,
    child2Ref,
    lineageRef,
    parent1Ref,
    parent2Ref,
    strainRef,
    strainTextRef,
  };

  return (
    <>
      <Section
        className="w-full"
        title={`${name} strain genetics`}
        id="strain-lineage-section"
      >
        <div className="lineage__master mt-xl mb-md mx-auto ">
          <TrackImpression
            label="strain page lineage section view"
            category="Strain lineage"
          >
            <StrainLineageOverlay
              refs={refs}
              numberOfParents={numberOfParents}
              numberOfChildren={numberOfChildren}
            />
            <div
              className="font-bold lineage__container px-lg text-sm"
              ref={lineageRef}
            >
              {parents?.length === 1 && (
                <StrainLineageSingleParent
                  parentRef={parent1Ref}
                  parent={parents[0]}
                />
              )}
              {parents?.length === 2 && (
                <StrainLineageTwoParents
                  parent1Ref={parent1Ref}
                  parent2Ref={parent2Ref}
                  parents={parents}
                />
              )}
              <div
                className={`lineage__strain--${parentalClassExtension} text-center flex flex-col items-center`}
                ref={strainRef}
              >
                <StrainLineageNuclei
                  altText={name}
                  mainOrRelative="main"
                  strainSymbol={symbol}
                  topTerpName={extractTopTerp(terps)?.name.toLowerCase()}
                />
                <div ref={strainTextRef}>{name}</div>
              </div>
              {children?.length === 1 && (
                <StrainLineageSingleChild
                  childRef={child1Ref}
                  child={children[0]}
                  parentalClassExtension={parentalClassExtension}
                />
              )}
              {children?.length === 2 && (
                <StrainLineageTwoChildren
                  child1Ref={child1Ref}
                  child2Ref={child2Ref}
                  parentalClassExtension={parentalClassExtension}
                >
                  {children}
                </StrainLineageTwoChildren>
              )}
            </div>
          </TrackImpression>
        </div>
      </Section>
      <style jsx>{`
        .lineage__master {
          max-width: 450px;
        }
        .lineage__container {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-auto-rows: 1fr;
          grid-gap: 5px;
          justify-items: center;
          align-items: start;
        }
        .lineage__strain--two-parents {
          grid-column: 1 / 7;
          grid-row: 3 / 5;
        }
        .lineage__strain--single-parent {
          grid-column: 1 / 7;
          grid-row: 4 / 6;
        }
        .lineage__strain--no-parents {
          grid-column: 1 / 7;
          grid-row: 1 / 5;
        }
      `}</style>
    </>
  );
};

export default StrainLineage;
