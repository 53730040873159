import React from "react";
import classNames from "classnames";

const HIGHLIGHT_ROW_WIDTH = 992;
const HIGHLIGHT_HEADER_WIDTH = 157;
const ROW_WIDTH = 968;
const HEADER_WIDTH = 145;

type Props = {
  title?: string;
  bottomBorder?: boolean;
  highlight?: boolean;
  children: React.ReactNode;
};

const ComparisonRow = ({
  children,
  title,
  bottomBorder = true,
  highlight = false,
}: Props) => {
  const rowClasses = classNames("p-none m-none flex items-center", {
    "bg-yellow-20 pr-md": highlight,
    "border-b border-grey": bottomBorder,
    "mx-md": !highlight,
  });

  const headClasses = classNames(
    "m-none p-sm h-full self-start font-bold text-xs text-left",
    {
      "pl-none": !highlight,
      "text-deep-green font-extrabold pl-md": highlight,
    },
  );

  return (
    <tr
      className={rowClasses}
      style={{ width: highlight ? HIGHLIGHT_ROW_WIDTH : ROW_WIDTH }}
    >
      {title ? (
        <th
          className={headClasses}
          style={{ width: highlight ? HIGHLIGHT_HEADER_WIDTH : HEADER_WIDTH }}
          scope="row"
          role="rowheader"
        >
          {title}
        </th>
      ) : (
        <td style={{ width: HEADER_WIDTH }}></td>
      )}
      {children}
    </tr>
  );
};

export default ComparisonRow;
