import Agent from "agentkeepalive";
import axios from "axios";

import publicConfig from "config/public";

const keepAliveConfig: Agent.HttpOptions = {
  freeSocketTimeout: 30000,
  keepAlive: true,
  maxFreeSockets: 256,
  maxSockets: Infinity,
  timeout: 60000,
};

// Http agents are not isomorphic and only should be created and used on the server
const httpAgent =
  typeof window === "undefined" ? new Agent(keepAliveConfig) : undefined;
const httpsAgent =
  typeof window === "undefined"
    ? new Agent.HttpsAgent(keepAliveConfig)
    : undefined;

const app = publicConfig.serviceRequest.headers.app;
const environment = publicConfig.serviceRequest.headers.environment;

const serviceRequest = () => {
  const leaflyServiceRequest = axios.create({
    headers: {
      "X-App": app,
      "X-Environment": environment,
      ...(typeof window === "undefined" && {
        "User-Agent": `${app} / ${environment}`,
      }),
    },
    httpAgent,
    httpsAgent,
  });

  leaflyServiceRequest.defaults.timeout = 10000;
  leaflyServiceRequest.interceptors.request.use((config) => {
    if (config.headers) {
      config.headers["Accept"] = "application/json";
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  });

  return leaflyServiceRequest;
};

export default serviceRequest;
