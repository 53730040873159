"use client";

import React from "react";
import dynamic from "next/dynamic";
import { useMediaQuery } from "usehooks-ts";

import { use420Timeout } from "hooks/use420Timeout";

import { useWeedfetti } from "./useWeedfetti";

const WeedfettiParticles = dynamic(() => import("./WeedfettiParticles"));

export function WeedfettiCannon() {
  const { instances, cleanup, launchWeedfetti } = useWeedfetti();

  const prefersReducedMotion = useMediaQuery("(prefers-reduced-motion)");
  use420Timeout(() => {
    if (
      !prefersReducedMotion &&
      typeof document !== "undefined" &&
      document.visibilityState === "visible"
    ) {
      launchWeedfetti({
        colors: ["#017C6B", "#80BEB5", "#FFCD00", "#5C068C"],
        theme: "fourtwenty",
      });
    }
  });

  return (
    <>
      {instances.map(({ id, colors, theme }) => (
        <WeedfettiParticles
          key={id}
          colors={colors}
          onConfettiComplete={() => cleanup(id)}
          theme={theme}
        />
      ))}
    </>
  );
}
