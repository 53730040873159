import React, { Ref, useEffect, useState } from "react";
import { trackEvent, trackNonInteractionEvent } from "@leafly-com/web-utils";
import isEmpty from "lodash/isEmpty";
import Link from "next/link";
import pluralize from "pluralize";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";

import { getAvailableMenuItems } from "api/requests/getAvailableMenuItems";
import getSimilarStrains from "api/requests/getSimilarStrains";
import { useStrainPageContext } from "context/StrainPageContext";
import { MenuItemWithCategories } from "custom-types/MenuItem";
import { Strain } from "custom-types/Strain";
import { getCoordinates, getLocationState } from "redux/selectors/location";
import { moveObjToSortedArray } from "utils/moveObjToSortedArray";
import { sortTopCannabs } from "utils/sortTopCannabs";

import SectionHeader from "components/botanic/SectionHeader";
import EffectIcon from "components/Icons/EffectIcon";
import TerpeneIcon from "components/Icons/terpene.svg";
import Placeholder, { Rect } from "components/Placeholder";

import ComparisonColumn from "./ComparisonColumn";
import ComparisonRow from "./ComparisonRow";
import StrainImage from "./StrainImage";

const terpHierarchy: string[] = [
  "primary_terpene",
  "secondary_terpene",
  "tertiary_terpene",
];

const StrainComparison = () => {
  const { strain } = useStrainPageContext();

  const coordinates = useSelector(getCoordinates);

  const [similarStrains, setSimilarStrains] = useState<
    Strain[] | Record<string, never>[]
  >([{}, {}, {}, {}, {}]);
  const [strainsAvailability, setStrainsAvailability] = useState<
    MenuItemWithCategories[] | []
  >([]);

  const { formattedLocation, zip } = useSelector(getLocationState);
  const locationText = formattedLocation || zip;

  useEffect(() => {
    setSimilarStrains([{}, {}, {}, {}, {}]);
    setStrainsAvailability([]);
  }, [strain.slug]);

  const fetchData = (inView: boolean) => {
    if (inView) {
      trackNonInteractionEvent(
        "Carousel",
        "Impression",
        "Similar Strains Carousel",
      );

      const params: Record<string, undefined | number | string> = {
        cbd: undefined as undefined | number,

        effect: undefined as undefined | string,

        energize_score: undefined as undefined | number,

        primary_terpene: undefined as undefined | string,

        secondary_terpene: undefined as undefined | string,

        skip: 0,

        strain_id: strain.id,
        //have to take one more than needed in case the strain itself is returned
        take: 5,
        tertiary_terpene: undefined as undefined | string,
        thc: undefined as undefined | number,
      };

      const {
        cannabinoids: { cbd, thc },
        terps,
        energizeScore,
        topEffect,
      } = strain;

      if (energizeScore) {
        params.energize_score = energizeScore;
      }

      if (topEffect) {
        params.effect = topEffect;
      }

      if (thc.percentile50) {
        params.thc = Math.floor(thc.percentile50);
      }
      if (cbd.percentile50) {
        params.cbd = Math.floor(cbd.percentile50);
      }

      if (!isEmpty(terps)) {
        const terpenes = Object.values(terps).sort(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
          (a: any, b: any) => b.score - a.score,
        );

        terpHierarchy.forEach((hierarchy, i) => {
          if (terpenes[i]) {
            params[hierarchy] = terpenes[i].name.toLowerCase();
          }
        });
      }

      getSimilarStrains(params, coordinates)
        .then((data) => {
          if (data?.length) {
            const filteredStrains = data
              .filter(
                (similarStrain: Strain) => similarStrain.slug !== strain.slug,
              )
              .slice(0, 4);
            const strainsArray = [strain, ...filteredStrains];

            setSimilarStrains(strainsArray);

            const strainNames = strainsArray.map((strain) => {
              return strain.name;
            });

            getAvailableMenuItems(coordinates, strainNames).then((data) => {
              if (data?.length) {
                setStrainsAvailability(data);
              }
            });
          } else {
            setSimilarStrains([]);
          }
        })
        .catch(() => setSimilarStrains([]));
    }
  };

  if (isEmpty(strain)) {
    return <></>;
  }

  return (
    <InView
      triggerOnce={true}
      key={strain.slug}
      onChange={(inView) => fetchData(inView)}
    >
      {({ ref }: { ref: Ref<HTMLDivElement> }) => (
        <div
          ref={ref}
          id="similar-strains-section"
          className="mt-xxl container"
        >
          <SectionHeader
            className="mb-xs inline lg:flex"
            headerClassName="mb-md lg:mb-none heading--l"
          >
            <span>{`Similar to ${strain.name} ${
              locationText ? `near ${locationText}` : ""
            }`}</span>
          </SectionHeader>
          <div className="text-sm mt-md text-left">
            <span className="relative mr-sm">
              <span
                className="border border-default inline-block"
                style={{ borderRadius: "50%", height: 10, width: 10 }}
              />
              <span
                className="border border-default absolute"
                style={{
                  borderRadius: "50%",
                  height: 10,
                  left: 5,
                  top: 5,
                  width: 10,
                }}
              />
            </span>
            <span className="font-bold">Similar strains:</span> We used science
            to find strains with similar terpenes and effects
          </div>
          <div className="w-full overflow-x-auto lg:overflow-hidden">
            <table>
              <tbody>
                <ComparisonRow bottomBorder={false}>
                  {similarStrains.map((strain, index) => {
                    return (
                      <ComparisonColumn columnHeader={true} key={index}>
                        {!strain.slug ? (
                          <Placeholder
                            width="164"
                            height="200"
                            viewBox="0 0 164 200"
                          >
                            <Rect x="8" y="140" width="148" height="24" />
                            <circle cx="82" cy="72" r="60" />
                            <Rect x="8" y="176" width="148" height="16" />
                          </Placeholder>
                        ) : (
                          <StrainImage
                            strain={strain as Strain}
                            showSimilarStrainIcon={index !== 0}
                          />
                        )}
                      </ComparisonColumn>
                    );
                  })}
                </ComparisonRow>
                <ComparisonRow bottomBorder={false}>
                  {similarStrains.map((strain, index) => {
                    const filteredCategories = strainsAvailability.filter(
                      (availability) => availability.name === strain.name,
                    )[0]?.menuItemCategories;

                    return (
                      <ComparisonColumn key={index}>
                        {isEmpty(strain) ? (
                          <Placeholder width="100%" height="30">
                            <Rect
                              y="0"
                              rx="16"
                              ry="16"
                              width="100%"
                              height="100%"
                            />
                          </Placeholder>
                        ) : !isEmpty(strain) && isEmpty(filteredCategories) ? (
                          <p className="text-xs text-secondary my-sm">
                            out of stock
                          </p>
                        ) : (
                          <Link
                            className="button text-center font-bold rounded-full inline-block bg-green text-white"
                            href={`/search/shop?shopCategory=strain&shopName=${encodeURIComponent(
                              strain.name,
                            )}`}
                            onClick={() => {
                              trackEvent(
                                "Strain Comparison",
                                "Click",
                                "Shop Now",
                                {
                                  strainId: strain.id,
                                },
                              );
                            }}
                          >
                            shop menus
                          </Link>
                        )}
                      </ComparisonColumn>
                    );
                  })}
                </ComparisonRow>
                <ComparisonRow
                  title="IN STOCK PRODUCTS NEAR YOU"
                  highlight={true}
                  bottomBorder={false}
                >
                  {similarStrains.map((strain, index) => {
                    const filteredCategories = strainsAvailability.filter(
                      (availability) => availability.name === strain.name,
                    )[0]?.menuItemCategories;

                    return (
                      <ComparisonColumn key={index}>
                        {filteredCategories?.map((category, catIndex) => {
                          const categoryDisplayName =
                            category.name.toLowerCase() === "flower"
                              ? "flower"
                              : `${pluralize(category.name)}`;
                          return (
                            <div key={`cat-${catIndex}`}>
                              <Link
                                className="text-xs text-green underline mr-sm"
                                href={`/search/shop?shopCategory=strain&shopName=${strain.name}&product_category=${category.name}`}
                                onClick={() => {
                                  trackEvent(
                                    "Strain Comparison",
                                    "Click",
                                    `strain comparison category ${category.name}`,
                                    {
                                      strainId: strain.id,
                                    },
                                  );
                                }}
                              >
                                {`shop ${categoryDisplayName.toLowerCase()}`}
                              </Link>
                            </div>
                          );
                        })}
                      </ComparisonColumn>
                    );
                  })}
                </ComparisonRow>
                <ComparisonRow title={"Type"}>
                  {similarStrains.map((strain, index) => (
                    <ComparisonColumn key={index}>
                      {strain.category}
                    </ComparisonColumn>
                  ))}
                </ComparisonRow>
                <ComparisonRow title={"Cannabinoid"}>
                  {similarStrains.map((strain, index) => {
                    const sortedTopCannabs = sortTopCannabs(
                      strain.cannabinoids,
                    ).slice(0, 2);
                    return (
                      <ComparisonColumn key={index}>
                        {sortedTopCannabs.length > 0 ? (
                          sortedTopCannabs.map((cannab) => (
                            <span className="mr-md" key={cannab.displayName}>
                              {cannab.displayName}{" "}
                              {cannab.percentile50 !== null &&
                              (cannab.percentile50 || 0) >= 0 ? (
                                `${cannab.percentile50}%`
                              ) : (
                                <>&#8212;</>
                              )}
                            </span>
                          ))
                        ) : (
                          <></>
                        )}
                      </ComparisonColumn>
                    );
                  })}
                </ComparisonRow>
                <ComparisonRow title={"Top reported effect"}>
                  {similarStrains.map((strain, index) => {
                    const topEffect = strain.topEffect;
                    return (
                      <ComparisonColumn key={index}>
                        {!topEffect ? (
                          <>&#8212;</>
                        ) : (
                          <div className="text-xs flex items-center">
                            <EffectIcon effect={topEffect} />
                            <div className="text-xs capitalize ml-xs">
                              {topEffect}
                            </div>
                          </div>
                        )}
                      </ComparisonColumn>
                    );
                  })}
                </ComparisonRow>
                <ComparisonRow title={"Dominant terpene"} bottomBorder={false}>
                  {similarStrains.map((strain, index) => {
                    const terpName =
                      !isEmpty(strain.terps) &&
                      moveObjToSortedArray(strain.terps, "score")[0].name;
                    return (
                      <ComparisonColumn key={index}>
                        {isEmpty(strain.terps) ? (
                          <>&#8212;</>
                        ) : (
                          <div className="text-xs flex items-center">
                            <span className="flex items-center">
                              <TerpeneIcon
                                width="8"
                                height="17"
                                className={`text-${terpName.toLowerCase()}`}
                              />
                              <span className="ml-sm capitalize">
                                {terpName}
                              </span>
                            </span>
                          </div>
                        )}
                      </ComparisonColumn>
                    );
                  })}
                </ComparisonRow>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </InView>
  );
};

export default StrainComparison;
