import { useState } from "react";
import {
  differenceInMilliseconds,
  isFriday,
  isSameMinute,
  isWithinInterval,
  nextFriday,
  set,
  subMinutes,
} from "date-fns";
import { useTimeout } from "usehooks-ts";

/**
 * Calls the given callback at 4:20 on Fridays.
 */
export const use420Timeout = (callback: () => unknown) => {
  const [timeoutDelay, setTimeoutDelay] = useState<number | null>(0);

  useTimeout(() => {
    const now = new Date();
    const targetDate = isFriday(now) ? now : nextFriday(now);
    const targetTime = set(targetDate, {
      hours: 16,
      milliseconds: 0,
      minutes: 20,
      seconds: 0,
    });

    const setFourTwentyTimeoutByTime = (time: Date) =>
      setTimeoutDelay(differenceInMilliseconds(time, now));

    if (isSameMinute(now, targetTime)) {
      callback();
      /**
       * Disable any further timeouts; we're done for this session.
       */
      setTimeoutDelay(null);
    } else if (
      isWithinInterval(now, {
        end: targetTime,
        start: subMinutes(targetTime, 5),
      })
    ) {
      /**
       * Set final timeout only once we're within five minutes of the target,
       * to avoid setting a very large timeout duration when we want a reasonable
       * amount of accuracy.
       */
      setFourTwentyTimeoutByTime(targetTime);
    } else {
      /**
       * Set initial timeout to one minute before target in order to avoid any
       * innacuracy that might be introduced from a very long timeout.
       */
      setFourTwentyTimeoutByTime(set(targetTime, { minutes: 19 }));
    }
  }, timeoutDelay);
};
