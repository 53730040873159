"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * WARNING!!! Any data you return here will be accessible in the frontend.
 * It will be exposed, regardless of how you use it later.
 * Only put things here if you are OK with that data being publicly available.
 *
 *                                    ████████
 *                                ████▒▒    ▒▒████
 *                              ██▓▓            ████
 *                          ▒▒██                  ████
 *                          ██                      ██
 *                  ████  ██▒▒                        ██
 *              ██▓▓    ▓▓██                          ██
 *            ██                                      ██
 *            ██            ▒▒██████▓▓                ██
 *          ██            ██          ██              ████
 *          ██          ██    ██████▒▒  ██                ██
 *      ██████        ██  ▓▓██      ████  ██                ██
 *    ██▓▓            ██  ██          ██  ██                ██
 *  ████              ██  ██          ██  ██                ██
 *  ██                ██  ██          ██  ██                ██
 *  ██                ██  ██          ██  ██                ██
 *  ██              ██████████████████████████            ██
 *    ██          ██                          ██        ████
 *      ████████████                          ██████████
 *                ██          ██████          ██
 *                ██          ██  ██          ██
 *                ██          ██████          ██
 *                ██            ██            ██
 *                ██            ██            ██
 *                ██                          ██
 *                ██                          ██
 *                ████████████████████████████▓▓
 */
exports.default = {
    assetPrefixUrl: process.env.NEXT_PUBLIC_ASSET_PREFIX_URL,
    chartbeat: {
        enabled: process.env.NEXT_PUBLIC_CHARTBEAT_ENABLED === "true",
    },
    cookieDomain: String(process.env.NEXT_PUBLIC_COOKIE_DOMAIN),
    cookieDomainCa: String(process.env.NEXT_PUBLIC_COOKIE_CA_DOMAIN),
    datadogClientToken: String(process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN),
    datadogRum: {
        applicationId: String(process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID),
        clientToken: String(process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN),
        enabled: process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED === "true",
        sampleRate: Number(process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE),
    },
    googleMapsApiKey: String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_CLIENT_API_KEY),
    imgix: {
        cmsIntegrationUrl: String(process.env.NEXT_PUBLIC_IMGIX_CMS_INTEGRATION_URL),
        cmsProductionUrl: String(process.env.NEXT_PUBLIC_IMGIX_CMS_PRODUCTION_URL),
        directusUrl: String(process.env.NEXT_PUBLIC_IMGIX_DIRECTUS_URL),
        imagesUrl: String(process.env.NEXT_PUBLIC_IMGIX_IMAGES_URL),
        publicUrl: String(process.env.NEXT_PUBLIC_IMGIX_PUBLIC_URL),
    },
    jane: {
        checkoutId: String(process.env.NEXT_PUBLIC_JANE_CHECKOUT_ID),
        checkoutUrl: String(process.env.NEXT_PUBLIC_JANE_CHECKOUT_URL),
    },
    leaflyCaDomain: String(process.env.NEXT_PUBLIC_LEAFLY_CA_DOMAIN),
    leaflyCaHostname: String(process.env.NEXT_PUBLIC_LEAFLY_CA_HOSTNAME),
    leaflyDomain: String(process.env.NEXT_PUBLIC_LEAFLY_DOMAIN),
    leaflyHostname: String(process.env.NEXT_PUBLIC_LEAFLY_HOSTNAME),
    oneSignalId: process.env.NEXT_PUBLIC_ONE_SIGNAL_ID,
    robots: {
        noindexAll: process.env.NEXT_PUBLIC_ROBOTS_NOINDEX_ALL === "true",
    },
    serviceRequest: {
        headers: {
            app: "web-web",
            environment: String(process.env.NEXT_PUBLIC_SERVICE_REQUEST_X_ENVIRONMENT_HEADER),
        },
    },
    services: {
        apiOg: {
            url: String(process.env.NEXT_PUBLIC_API_OG_URL),
        },
        apiOgInternal: {
            url: String(process.env.NEXT_PUBLIC_API_OG_INTERNAL_URL),
        },
        chatApi: {
            url: String(process.env.NEXT_PUBLIC_CHAT_API_URL),
        },
        consumerApi: {
            url: String(process.env.NEXT_PUBLIC_CONSUMER_API_URL),
        },
        consumerApiCa: {
            url: String(process.env.NEXT_PUBLIC_CONSUMER_API_CA_URL),
        },
        directusApi: {
            token: String(process.env.NEXT_PUBLIC_DIRECTUS_TOKEN),
            url: String(process.env.NEXT_PUBLIC_DIRECTUS_URL),
        },
        finderApi: {
            url: String(process.env.NEXT_PUBLIC_FINDER_API_URL),
        },
        forbiddenFruit: {
            url: String(process.env.NEXT_PUBLIC_FORBIDDEN_FRUIT_URL),
        },
        geoApi: {
            url: String(process.env.NEXT_PUBLIC_GEO_API_URL),
        },
        geoIpApi: {
            url: String(process.env.NEXT_PUBLIC_GEO_IP),
        },
        orderApi: {
            url: String(process.env.NEXT_PUBLIC_ORDER_API_URL),
        },
        postalServiceApi: {
            url: String(process.env.NEXT_PUBLIC_POSTAL_SERVICE_API_URL),
        },
        reservationsApi: {
            url: String(process.env.NEXT_PUBLIC_RESERVATIONS_API),
        },
        reservationsApiCa: {
            url: String(process.env.NEXT_PUBLIC_RESERVATIONS_API_CA),
        },
        sso: {
            url: String(process.env.NEXT_PUBLIC_SSO_URL),
        },
        ssoCa: {
            url: String(process.env.NEXT_PUBLIC_SSO_URL_CA),
        },
        ssoInternal: {
            url: String(process.env.NEXT_PUBLIC_SSO_INTERNAL_URL),
        },
        userNotificationApi: {
            url: String(process.env.NEXT_PUBLIC_USER_NOTIFICATION_SERVICE_URL),
        },
        userNotificationCaApi: {
            url: String(process.env.NEXT_PUBLIC_USER_NOTIFICATION_SERVICE_CA_URL),
        },
        userProfileApi: {
            url: String(process.env.NEXT_PUBLIC_USER_PROFILE_SERVICE_URL),
        },
        userProfileCaApi: {
            url: String(process.env.NEXT_PUBLIC_USER_PROFILE_SERVICE_CA_URL),
        },
    },
    splitClientKey: process.env.NEXT_PUBLIC_SPLIT_CLIENT_KEY,
    storeLocatorMapId: String(process.env.NEXT_PUBLIC_GOOGLE_MAPS_STORE_LOCATOR_MAP_ID),
    test: {
        synchronousChat: process.env.NEXT_PUBLIC_TEST_SYNCHRONOUS_CHAT === "true",
    },
    timeout: {
        deliverySearch: Number(process.env.NEXT_PUBLIC_LEAFLY_DELIVERY_SEARCH_TIMEOUT),
        storeSearch: Number(process.env.NEXT_PUBLIC_LEAFLY_STORE_SEARCH_TIMEOUT),
    },
};
