import { HYDRATE, HydrateAction } from "redux/reducers/hydration";

import { WeedfettiThemeName } from "components/Weedfetti/themes";

const MAXIMUM_INSTANCE_COUNT = 3;

export type Weedfetti = {
  id: string;
  colors?: string[];
  theme?: WeedfettiThemeName;
};

export type WeedfettiState = {
  instances: Weedfetti[];
};

export const initialState: WeedfettiState = {
  instances: [],
};

export const WEEDFETTI_CLEANUP = "weedfetti/cleanup";
export const WEEDFETTI_LAUNCH = "weedfetti/launch";

export type WeedfettiActions =
  | { type: typeof WEEDFETTI_CLEANUP; value: string }
  | {
      type: typeof WEEDFETTI_LAUNCH;
      value: { colors?: string[]; theme?: WeedfettiThemeName };
    }
  | HydrateAction;

export const weedfettiReducer = (
  state = initialState,
  action: WeedfettiActions,
): WeedfettiState => {
  switch (action.type) {
    case HYDRATE:
      /**
       * Ignore any hydration events after initial page load to preserve any
       * subsequent changes that have been made on the client.
       */
      return state;
    case WEEDFETTI_LAUNCH: {
      if (state.instances.length >= MAXIMUM_INSTANCE_COUNT) {
        return state;
      } else {
        const id = createId();
        return { instances: [...state.instances, { id, ...action.value }] };
      }
    }
    case WEEDFETTI_CLEANUP:
      return {
        instances: state.instances.filter(
          (instance) => instance.id !== action.value,
        ),
      };
    default:
      return state;
  }
};

/**
 * Creates a random ten letter string for use as a unique-ish ID
 */
function createId() {
  return Math.random().toString(36).substring(10);
}

export default weedfettiReducer;
