"use client";

import { useDispatch, useSelector } from "react-redux";
import { useEventCallback } from "usehooks-ts";

import { RootState } from "redux/reducers/rootReducer";
import {
  Weedfetti,
  WEEDFETTI_CLEANUP,
  WEEDFETTI_LAUNCH,
} from "redux/reducers/weedfetti";

import { WeedfettiThemeName } from "./themes";

export const useWeedfetti = (): {
  cleanup: (id: string) => void;
  instances: Weedfetti[];
  launchWeedfetti: (options?: {
    colors?: string[];
    theme?: WeedfettiThemeName;
  }) => void;
} => {
  const dispatch = useDispatch();
  const instances = useSelector(
    (state: RootState) => state.weedfetti.instances,
  );
  const cleanup = useEventCallback((id: string) => {
    dispatch({ type: WEEDFETTI_CLEANUP, value: id });
  });
  const launchWeedfetti = useEventCallback(
    ({
      colors,
      theme,
    }: { colors?: string[]; theme?: WeedfettiThemeName } = {}) => {
      dispatch({ type: WEEDFETTI_LAUNCH, value: { colors, theme } });
    },
  );

  return {
    cleanup,
    instances,
    launchWeedfetti,
  };
};
