import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useEventCallback } from "usehooks-ts";

import { Action } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";

type Props = {
  /**
   * Action tracking string to be passed to the tracking function. Default is "impression"
   */
  action?: string;
  /**
   * Category tracking string to be passed to the tracking function
   */
  category?: string;

  /**
   * Children to be rendered
   */
  children?: React.ReactNode;

  className?: string;

  /**
   * Custom dimensions object to be passed to the tracking function.
   * Default is an empty object
   */
  customDimensions?: Record<
    string,
    string | number | boolean | undefined | null
  >;

  id?: string;

  /**
   * label tracking string to be passed to the tracking function
   */
  label?: string;

  style?: object;

  /**
   * Provide a number from 0-1 that represents the percentage of the component that must be in view for the tracking function to be fired.
   * Default is 0.5 for 50%
   */
  threshold?: number;

  /**
   * Provide your own tracking function that is called when the component comes into view.
   * Default is the trackEvent function from web-utils
   */
  trackingFunction?: () => void;
  /**
   * Sets if we track a single time, or every time the component comes into view
   * Default is true
   */
  triggerOnce?: boolean;
};

const TrackImpression = (props: Props) => {
  const { trackNonInteractionEvent } = useEventTracker();
  const {
    trackingFunction = trackNonInteractionEvent,
    threshold = 0.5,
    label,
    category,
    action = Action.impression,
    customDimensions = {},
    triggerOnce = true,
    children,
    ...otherProps
  } = props;
  const [ref, inView] = useInView({
    threshold,
    triggerOnce,
  });

  useEffect(() => {
    async function loadPolyfills() {
      // @ts-ignore (fix me please, do not replicate)
      await import("intersection-observer");
    }
    if (typeof window.IntersectionObserver === "undefined") {
      loadPolyfills();
    }
  });

  const onView = useEventCallback(() => {
    trackingFunction(category, action, label, customDimensions);
  });

  useEffect(() => {
    if (inView) {
      onView();
    }
  }, [inView]);

  return (
    <div ref={ref} {...otherProps}>
      {children}
    </div>
  );
};

export default TrackImpression;
