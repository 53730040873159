"use client";

import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { setCookie } from "cookies-next";
import { usePathname } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { UAParser } from "ua-parser-js";
import { useEventCallback } from "usehooks-ts";

import publicConfig from "config/public";
import { Action, Category, Label } from "constants/events";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { HIDE_BANNER, SHOW_BANNER } from "redux/reducers/header";
import { getShowBanner } from "redux/selectors/header";
import isMobileDevice from "utils/isMobileDevice";

import BannerLogo from "components/Icons/download_banner_logo.svg";
import StarSolidIcon from "components/Icons/star_solid.svg";
import CloseIcon from "components/Icons/x.svg";

const BANNER_DATA = {
  appName: "Leafly",
  cta: "Open",
  desc: "Shop legal, local weed.",
  storeUrl: "https://onelink.to/leaflysmartbanner",
};

const { cookieDomain, cookieDomainCa } = publicConfig;

const isBlockedPage = (pathname: string) =>
  pathname === "/" ||
  new RegExp("^(/bag|/checkout|/news|/learn).*$").test(pathname);

export const Banner = ({
  disableFixedPosition = false,
  isAppBannerCookieDismissed = false,
  minimal = false,
}: {
  disableFixedPosition?: boolean;
  isAppBannerCookieDismissed?: boolean;
  minimal?: boolean;
}) => {
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { publishEvent, trackNonInteractionEvent } = useEventTracker();
  const countryCode = useDomainCountryCode();

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const showBanner = useSelector(getShowBanner);

  const onFirstLoad = useEventCallback(() => {
    const shouldShowBanner =
      !isAppBannerCookieDismissed &&
      !disableFixedPosition &&
      !minimal &&
      !isBlockedPage(String(pathname)) &&
      isMobileDevice() &&
      new UAParser().getOS().name === "iOS";

    if (shouldShowBanner) {
      trackNonInteractionEvent(
        Category.header,
        Action.impression,
        Label.appDownloadBanner,
      );

      dispatch({ type: SHOW_BANNER });
    }
  });

  useEffect(() => {
    onFirstLoad();
  }, []);

  const dismissAppBanner = () => {
    publishEvent({
      action: Action.click,
      category: Category.header,
      label: Label.appDownloadClose,
    });

    setCookie("appBannerDismissed", true, {
      domain: countryCode === "CA" ? cookieDomainCa : cookieDomain,
      expires: new Date(new Date().setDate(new Date().getDate() + 30)),
      path: "/",
    });

    dispatch({ type: HIDE_BANNER });
  };

  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.tabIndex = showBanner ? 0 : -1;
    }
    if (linkRef.current) {
      linkRef.current.tabIndex = showBanner ? 0 : -1;
    }
  }, [showBanner]);

  return (
    <div
      className={classNames("w-full lg:overflow-hidden lg:h-0", {
        "h-0": disableFixedPosition && !showBanner,
        "h-[82px]": disableFixedPosition && showBanner,
        "transition-[height] motion-reduce:transition-none overflow-hidden":
          disableFixedPosition,
      })}
      data-testid="header-banner"
      aria-hidden={!showBanner}
    >
      <div className="bg-white flex justify-between items-center py-3 px-4 border border-light-grey">
        <div className="flex items-center">
          <button
            className="text-default"
            aria-label="Close app download banner"
            onClick={dismissAppBanner}
            data-testid="dismiss-app-banner"
            ref={closeButtonRef}
          >
            <CloseIcon width="15.3" height="15.3" />
          </button>
          <div className="flex items-center ml-4" style={{ height: 56 }}>
            <BannerLogo width="56" height="56" />
            <div className="flex flex-col ml-3">
              <p className="text-sm font-bold mb-0 text-default">
                {BANNER_DATA.appName}
              </p>
              <div
                aria-label="Rating: 4.8 out fo 5 stars"
                className="leading-[0]"
                role="img"
              >
                {Array(5)
                  .fill(0)
                  .map((_, idx) => (
                    <div className="inline-block" key={idx}>
                      <StarSolidIcon
                        height="12"
                        width="12"
                        className="fill-[#1274b8]"
                      />
                    </div>
                  ))}
              </div>
              <p className="text-xs mb-0 text-default">{BANNER_DATA.desc}</p>
            </div>
          </div>
        </div>
        <a
          className="text-sm font-bold text-[#1274b8]"
          href={BANNER_DATA.storeUrl}
          target="_blank"
          rel="noreferrer"
          ref={linkRef}
          onClick={() => {
            publishEvent({
              action: Action.click,
              category: Category.header,
              label: Label.appDownloadOpen,
            });
          }}
        >
          {BANNER_DATA.cta}
        </a>
      </div>
    </div>
  );
};
